<template>
  <div id="tag-manage-container">
    <div class="ht-top">
      招募管理 <span>/ 招募列表</span>
      <div class="title">
        招募列表
      </div>
    </div>
    <div class="search-container">
      <el-form ref="form" :inline="true" label-width="112px" align="left" size="mini">
        <el-form-item label="招募关键词">
          <el-input
            placeholder="关键词"
            v-model="keyword"
            clearable>
          </el-input>
        </el-form-item>
        <el-form-item label="项目类别">
          <el-select v-model="projectType" placeholder="请选择" clearable>
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="招募地区">
          <el-select v-model="city" clearable filterable placeholder="请选择" value-key="codeDesc"  @change="change">
            <el-option
              v-for="item in cityList"
              :key="item.codeDesc"
              :label="item.codeDesc"
              :value="item.codeDesc">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="招募状态">
          <el-select v-model="recruitStatus" placeholder="请选择" clearable>
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="招募时间">
          <el-select v-model="timeFlag" placeholder="请选择" clearable>
            <el-option
              v-for="item in timeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="ht-button">
        <el-button @click="onReset">重置</el-button>
        <el-button type="primary" @click="onSearch">搜索</el-button>
      </div>
    </div>
    <div class="list-container">
      <div class="ht-middle">
        <h2 class="ht-title">招募列表</h2>
        <div>
          <el-button v-if="multipleSelection.length" type="primary" plain @click="handleBatchDelete">批量删除</el-button>
          <el-button type="primary" @click="addConference">新建招募</el-button>
        </div>
      </div>
      <el-table
        :data="list"
        stripe
        empty-text="没有相关数据"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          class-name="selection"
          width="50"
          fixed
        >
        </el-table-column>
        <el-table-column
          prop="id"
          label="id"
          min-width="50"
        >
        </el-table-column>
        <el-table-column
          prop="projectName"
          label="招募标题"
          min-width="250"
        >
        </el-table-column>
        <el-table-column
          prop="projectType"
          label="招募类别"
          width="100"
        >
          <template slot-scope="scope">
            {{ scope.row.projectType == 1 ? '健康人项目' : '患者项目' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="recruitStatus"
          label="招募状态"
          width="100"
        >
          <template slot-scope="scope">
            {{ scope.row.recruitStatus == 1 ? '招募中' : '已完结' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="createdTime"
          min-width="150"
          label="创建时间">
        </el-table-column>
        <el-table-column
          prop="address"
          width="250"
          fixed="right"
          label="操作">
          <template slot-scope="scope">
            <el-button @click="handleEdit(scope.row)" type="text">编辑</el-button>
            <el-button @click="goMembers(scope.row.id, scope.row.projectName)" type="text">查看数据</el-button>
            <el-button type="text" @click="handleUpdate(scope.row)">{{ scope.row.recruitStatus == 1 ? '完结招募' : '恢复招募' }}</el-button>
            <el-button type="text" @click="handleDelete([scope.row.id])">删除</el-button>
            <!-- <el-button type="text" v-if="scope.row.sendall === 2" @click="message(scope.row)">群发</el-button> -->
            <!-- <el-button type="text" @click="handleMessage(scope.row)">Message</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <div id="pagination">
        <el-pagination
          background
          hide-on-single-page
          layout="prev, pager, next, jumper"
          @current-change="getData"
          :current-page.sync="page"
          :total.sync="total">
        </el-pagination>
      </div>
    </div>
    <el-dialog :title="dialogName" :visible="editVisible" width="1000px" @close="closeEditDialog" :close-on-click-modal="false">
      <h3>基本问题</h3>
      <el-form ref="dialogForm" :model="newProject" :rules="rules" label-width="170px" @submit.native.prevent>
        <el-form-item label="招募标题" prop="projectName">
          <el-input v-model="newProject.projectName" placeholder="请输入招募标题"></el-input>
        </el-form-item>
        <el-form-item label="标签" prop="projectLableList">
          <el-tag
            :key="index"
            v-for="(tag, index) in newProject.projectLableList"
            closable
            style="margin-right: 15px;"
            :disable-transitions="false"
            @close="handleClose(index)">
            {{tag}}
          </el-tag>
          <el-input
            class="input-new-tag"
            v-if="inputVisible"
            v-model="inputValue"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="handleInputConfirm"
            @blur="handleInputConfirm"
          />
          <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 新增</el-button>
        </el-form-item>
        <el-form-item label="招募类别" prop="projectType">
          <el-select v-model="newProject.projectType">
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="对接人电话" prop="contacts">
          <el-input v-model="newProject.contacts" placeholder="请输入对接人电话"></el-input>
        </el-form-item>
        <el-form-item
          label="性别要求"
          prop="sexState"
        >
          <el-radio-group  v-model="newProject.sexState">
            <el-radio :label="0">无要求</el-radio>
            <el-radio :label="1">要求男</el-radio>
            <el-radio :label="2">要求女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="是否烟检"
          prop="smokeState"
        >
          <el-radio-group  v-model="newProject.smokeState">
            <el-radio :label="1">否</el-radio>
            <el-radio :label="2">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="药品名称" prop="goodsName">
          <el-input v-model="newProject.goodsName" placeholder="请输入药品名称"></el-input>
        </el-form-item>
        <el-form-item label="可选城市" prop="goodsName">
          <el-select v-model="newProject.projectCityList" multiple clearable filterable placeholder="请选择" value-key="codeDesc"  @change="change">
            <el-option
              v-for="item in cityList"
              :key="item.codeDesc"
              :label="item.codeDesc"
              :value="item.codeDesc">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年龄限制" prop="ageRequest">
          <el-input v-model="newProject.ageRequest" placeholder="例：18-35 或 无限制"></el-input>
        </el-form-item>
        <el-form-item label="BMI限制" prop="bmiRequest">
          <el-input v-model="newProject.bmiRequest" placeholder="例：20-31 或 无限制"></el-input>
        </el-form-item>
        <el-form-item label="营养补助" prop="subsidy">
          <el-input v-model="newProject.subsidy" placeholder="例：男 4000  女 4000"></el-input>
        </el-form-item>
        <el-form-item label="住院天数" prop="hospitalDay">
          <el-input-number v-model="newProject.hospitalDay" :min="0"></el-input-number>
        </el-form-item>
        <el-form-item label="体检时间" prop="bodyCheckTime">
          <el-date-picker
            v-model="newProject.bodyCheckTime"
            type="date"
            unlink-panels
            value-format="timestamp"
            class="dtpicker"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="截止时间" prop="endTime">
          <el-date-picker
            v-model="newProject.endTime"
            type="date"
            value-format="timestamp"
            unlink-panels
            class="dtpicker"
          >
          </el-date-picker>
        </el-form-item>
        <h3>主要研究者</h3>
        <el-form-item label="研究者姓名" prop="studyName">
          <el-input v-model="newProject.studyName" placeholder="请输入研究者姓名"></el-input>
        </el-form-item>
        <el-form-item label="研究者头像" prop="studyImage">
          <el-upload
            class="avatar-uploader"
            :with-credentials="true"
            action='/knmanager/banner/upFile'
            :data="{fileType: 'report'}"
            :show-file-list="false"
            :on-success="studyImageSuccess"
            :before-upload="beforeUploadImg">
            <img v-if="newProject.studyImage" :src="newProject.studyImage" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">Supported image formats: png, jpg. Image size: 1:1, Image size limit: ≤ 1M</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="研究者单位" prop="studyUnit">
          <el-input v-model="newProject.studyUnit" placeholder="请输入研究者单位"></el-input>
        </el-form-item>
        <el-form-item label="研究者简介" prop="studyDesc">
          <el-input v-model="newProject.studyDesc" placeholder="请输入研究者简介"></el-input>
        </el-form-item>
        <h3>其他设置</h3>
        <el-form-item
          label="招募概要"
          prop="projectDesc"
        >
          <el-input
            type="textarea"
            :rows="4"
            placeholder="请输入招募概要"
            v-model="newProject.projectDesc">
          </el-input>
          </el-form-item>
        <el-form-item
            label="参与获益"
            prop="projectInterestList"
          >
          <el-button type="primary" size="mini" @click="addInterest"><i class="el-icon-plus"></i>  新增</el-button>
          <el-form>
            <el-form-item
              v-for="(item, index) in newProject.projectInterestList"
              :key="index"
              :label='[`获益${index + 1}`]'
            >
              <el-input v-model="newProject.projectInterestList[index]" placeholder="请输入具体获益">
                <template slot="append"><i class="el-icon-remove-outline" @click="delInterest(index)"></i></template>
              </el-input>
            </el-form-item>
          </el-form>
        </el-form-item>
        <el-form-item
            label="参与流程"
            prop="projectPartinList"
          >
          <el-button type="primary" size="mini" @click="addStep"><i class="el-icon-plus"></i>  新增</el-button>
          <el-form>
            <el-form-item
              v-for="(item, index) in newProject.projectPartinList"
              :key="index"
              :label='[`流程${index + 1}`]'
            >
              <el-input v-model="newProject.projectPartinList[index]" placeholder="请输入具体流程">
                <template slot="append"><i class="el-icon-remove-outline" @click="delStep(index)"></i></template>
              </el-input>
            </el-form-item>
          </el-form>
        </el-form-item>
        <el-form-item
            label="研究中心"
            prop="projectInterestList"
          >
          <el-button type="primary" size="mini" @click="addStudy"><i class="el-icon-plus"></i>  新增</el-button>
          <el-form>
            <el-form-item
              v-for="(item, index) in newProject.studyCenterList"
              :key="index"
              :label='[`流程${index + 1}`]'
            >
              <el-input v-model="newProject.studyCenterList[index]" placeholder="请输入具体流程">
                <template slot="append"><i class="el-icon-remove-outline" @click="delStudy(index)"></i></template>
              </el-input>
            </el-form-item>
          </el-form>
        </el-form-item>
      </el-form>
      <div slot="footer" class="footer">
        <template v-if="dialogStatus === 'new' || dialogStatus === 'dup'">
          <el-button type="primary" @click="create">提交</el-button>
        </template>
        <template v-else>
          <el-button type="primary" @click="submit">保存</el-button>
          <el-button @click="reset">取消</el-button>
        </template>
      </div>
    </el-dialog>
    <el-dialog
      title="消息配置"
      :visible.sync="messageDialog"
      class="mem-dialog"
      width="420px">
      <el-form ref="mform">
        <el-form-item label="概况" prop="data1">
          <el-input v-model="data1" placeholder="请输入" type="textarea"></el-input>
        </el-form-item>
        <el-form-item  label="基本要求" prop="data2">
          <el-input v-model="data2" placeholder="请输入" type="textarea"></el-input>
        </el-form-item>
        <el-form-item  label="受试者获益" prop="data3">
          <el-input v-model="data3" placeholder="请输入" type="textarea"></el-input>
        </el-form-item>
        <el-form-item  label="体检日期"  prop="data4">
          <el-input v-model="data4" placeholder="请输入" type="textarea"></el-input>
        </el-form-item>
        <el-form-item  label="入住时间"  prop="data5">
          <el-input v-model="data5" placeholder="请输入" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="messageDialog = false">取消</el-button>
        <el-button :loading="commitTagLoading" type="primary" size="small" @click="send">发送</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import { VueEditor } from 'vue2-editor';
// import { regionData, CodeToText, TextToCode } from 'element-china-area-data';
import http from '@/lib/http';

export default {
  name: 'EventsManage',
  data() {
    return {
      data1: '',
      data2: '',
      data3: '',
      data4: '',
      data5: '',
      inputVisible: false,
      messageDialog: false,
      inputValue: '',
      page: 1,
      total: 0,
      city: '',
      keyword: '',
      projectType: 0,
      recruitStatus: 0,
      timeFlag: 0,
      cityList: [],
      typeList: [
        {
          value: 1,
          label: '健康人项目',
        },
        {
          value: 2,
          label: '患者项目',
        },
      ],
      typeOptions: [
        {
          value: 0,
          label: '全部类别',
        },
        {
          value: 1,
          label: '健康人项目',
        },
        {
          value: 2,
          label: '患者项目',
        },
      ],
      statusOptions: [
        {
          value: 0,
          label: '全部状态',
        },
        {
          value: 1,
          label: '招募中',
        },
        {
          value: 2,
          label: ' 已完结',
        },
      ],
      timeOptions: [
        {
          value: 0,
          label: '全部时间',
        },
        {
          value: 1,
          label: '最近三天',
        },
        {
          value: 2,
          label: ' 本周内',
        },
        {
          value: 3,
          label: ' 本月内',
        },
      ],
      dialogStatus: null,
      list: [],
      oldConference: {},
      newProject: {
        projectName: '',
        projectLableList: [],
        projectDesc: '',
        studyName: '',
        studyUnit: '',
        studyDesc: '',
        studyImage: '',
        goodsName: '',
        smokeState: 1,
        sexState: 0,
        hospitalDay: 0,
        bmiRequest: '',
        ageRequest: '',
        projectType: 1,
        bodyCheckTime: '',
        endTime: '',
        subsidy: '',
        projectInterestList: [],
        projectPartinList: [],
        studyCenterList: [],
        projectCityList: [],
        contacts: '',
      },
      listFileList: [],
      coverFileList: [],
      rules: {
        projectName: [{ required: true, message: '项目标题不能为空', trigger: 'change' }],
        contacts: [{ required: true, message: '对接人电话不能为空', trigger: 'change' }],
      },
      editVisible: false,
      multipleSelection: [],
      sendRow: {},
    };
  },
  computed: {
    dialogName() {
      return this.dialogStatus === 'new' ? '新建' : '编辑';
    },
  },
  async created() {
    await this.getCity();
    this.getData();
  },
  methods: {
    getData() {
      http.post('/project/getProject', {
        pageSize: 15,
        pageNum: this.page,
        city: this.city,
        filterStr: this.keyword,
        projectType: this.projectType,
        recruitStatus: this.recruitStatus,
        timeFlag: this.timeFlag,
      }).then((res) => {
        const { data, code, message } = res.data;
        if (code === 200) {
          if (data && data.dataList) {
            this.list = data.dataList || [];
            this.total = data.total || 0;
          } else {
            this.list = [];
            this.total = 0;
          }
        } else {
          this.$message({
            message,
            type: 'error',
          });
        }
      }).catch((err) => {
        this.$message({
          message: err || '网络错误',
          type: 'error',
        });
      });
    },
    getCity() {
      return new Promise((reslove, reject) => {
        http.get('/userCenter/getTableList?codeType=city').then((res) => {
          const { data, code, message } = res.data;
          if (code === 200) {
            this.cityList = data;
            reslove();
          } else {
            this.$message({
              message,
              type: 'error',
            });
            reject();
          }
        }).catch((err) => {
          this.$message({
            message: err || 'Network error',
            type: 'error',
          });
          reject();
        });
      });
    },
    onSearch() {
      this.getData();
    },
    onReset() {
      this.page = 1;
      this.total = 0;
      this.city = '';
      this.projectType = 0;
      this.recruitStatus = 0;
      this.keyword = '';
      this.timeFlag = 0;
      this.getData();
    },
    addConference() {
      this.dialogStatus = 'new';
      this.newProject = {
        projectName: '',
        projectLableList: [],
        projectDesc: '',
        studyName: '',
        studyUnit: '',
        studyDesc: '',
        studyImage: '',
        goodsName: '',
        smokeState: 1,
        sexState: 0,
        hospitalDay: 0,
        bmiRequest: '',
        ageRequest: '',
        projectType: 1,
        bodyCheckTime: '',
        endTime: '',
        subsidy: '',
        projectInterestList: [],
        projectPartinList: [],
        studyCenterList: [],
      };
      this.editVisible = true;
    },
    handleEdit(item) {
      this.dialogStatus = 'edit';
      this.newProject = Object.assign({}, item);
      if (this.newProject.bodyCheckTime) {
        this.newProject.bodyCheckTime = (new Date(this.newProject.bodyCheckTime.replace('-', '/'))).getTime();
      }
      if (this.newProject.endTime) {
        this.newProject.endTime = (new Date(this.newProject.endTime.replace('-', '/'))).getTime();
      }
      this.editVisible = true;
    },
    submit() {
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          const postData = Object.assign({}, this.newProject);
          http.post('/project/updateProject', {
            ...postData,
          }).then((res) => {
            const { code, message } = res.data;
            if (code === 200) {
              this.$message({
                message: '操作成功',
                type: 'success',
              });
              this.page = 1;
              this.getData();
              this.editVisible = false;
            } else {
              this.$message({
                message,
                type: 'error',
              });
            }
          }).catch((err) => {
            this.$message({
              message: err || 'Network error',
              type: 'error',
            });
          });
          return null;
        }
        return false;
      });
    },
    create() {
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          const postData = Object.assign({}, this.newProject);
          http.post('project/createProject', {
            ...postData,
          }).then((res) => {
            const { code, message } = res.data;
            if (code === 200) {
              this.$message({
                message: '操作成功',
                type: 'success',
              });
              this.page = 1;
              this.getData();
              this.editVisible = false;
            } else {
              this.$message({
                message,
                type: 'error',
              });
            }
          }).catch((err) => {
            this.$message({
              message: err || 'Network error',
              type: 'error',
            });
          });
          return null;
        }
        return false;
      });
    },
    handleUpdate(item) {
      this.$confirm('确认变更招募状态?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        http.post('project/setProjectStatus', {
          id: item.id,
          recruitStatus: item.recruitStatus === 1 ? 2 : 1,
        }).then((res) => {
          const { code, message } = res.data;
          if (code === 200) {
            this.$message({
              message: '变更成功',
              type: 'success',
            });
            this.getData();
          } else {
            this.$message({
              message,
              type: 'error',
            });
          }
        }).catch((err) => {
          this.$message({
            message: err || 'Network error',
            type: 'error',
          });
        });
      }).catch(() => {
      });
    },
    handleDelete(ids) {
      this.$confirm(`确认删除选中的${ids.length}个招募?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        http.post('project/delProject', {
          ids,
        }).then((res) => {
          const { code, message } = res.data;
          if (code === 200) {
            this.$message({
              message: '删除成功',
              type: 'success',
            });
            this.getData();
            this.editVisible = false;
          } else {
            this.$message({
              message,
              type: 'error',
            });
          }
        }).catch((err) => {
          this.$message({
            message: err || 'Network error',
            type: 'error',
          });
        });
      }).catch(() => {
      });
    },
    closeEditDialog() {
      this.editVisible = false;
      this.newProject = {
        projectName: '',
        projectLableList: [],
        projectDesc: '',
        studyName: '',
        studyUnit: '',
        studyDesc: '',
        studyImage: '',
        goodsName: '',
        smokeState: 1,
        sexState: 0,
        hospitalDay: 0,
        bmiRequest: '',
        ageRequest: '',
        projectType: 1,
        bodyCheckTime: '',
        endTime: '',
        subsidy: '',
        projectInterestList: [],
        projectPartinList: [],
        studyCenterList: [],
      };
      this.$nextTick(() => {
        this.$refs.dialogForm.clearValidate();
      });
    },
    studyImageSuccess(response, file, fileList) {
      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
      this.newProject.studyImage = response.data;
    },
    beforeUploadImg(file) {
      const isLt1M = (file.size / 1024 / 1024) <= 1;
      if (['image/jpg', 'image/jpeg', 'image/png'].indexOf(file.type) === -1) {
        this.$message.error('请检查图片格式');
        return false;
      }
      if (!isLt1M) {
        this.$message.error('图片大小超过 1G!');
        return false;
      }
    },
    reset() {
      // this.newProject = JSON.parse(JSON.stringify(this.oldConference));
      this.closeEditDialog();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleBatchDelete() {
      if (!this.multipleSelection.length) {
        return;
      }
      const ids = this.multipleSelection.map(item => item.id);
      this.handleDelete(ids);
    },
    addInterest() {
      this.newProject.projectInterestList.push('');
    },
    delInterest(index) {
      this.newProject.projectInterestList.splice(index, 1);
    },
    addStep() {
      this.newProject.projectPartinList.push('');
    },
    delStep(index) {
      this.newProject.projectPartinList.splice(index, 1);
    },
    addStudy() {
      this.newProject.studyCenterList.push('');
    },
    delStudy(index) {
      this.newProject.studyCenterList.splice(index, 1);
    },
    goMembers(pid, projectName) {
      this.$router.push({ path: '/members', query: { pid, projectName } });
    },
    handleClose(index) {
      this.newProject.projectLableList.splice(index, 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      const { inputValue } = this;
      if (inputValue) {
        this.newProject.projectLableList.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    },

    message(row) {
      this.sendRow = row;
      this.data1 = '';
      this.data2 = '';
      this.data3 = '';
      this.data4 = '';
      this.data5 = '';
      this.messageDialog = true;
    },

    send() {
      if (this.data1 && this.data2 && this.data3 && this.data4 && this.data5) {
        const data = {
          keywords1: {
            value: this.data1,
            color: '#459ae9',
          },
          keywords2: {
            value: this.data2,
            color: '#459ae9',
          },
          keywords3: {
            value: this.data3,
            color: '#459ae9',
          },
          keywords4: {
            value: this.data4,
            color: '#459ae9',
          },
          keywords5: {
            value: this.data5,
            color: '#459ae9',
          },
        };

        http.post('/wechat/sendTempMsgAll', {
          projectId: this.sendRow.id,
          data: JSON.stringify(data),
          templateId: '29usB8eM6XsfOe5W6tbYHhuCKiGwqUsk-4oIlRuxXng',
        }).then((res) => {
          const { code, message } = res.data;
          if (code === 200) {
            this.$message({
              message: '发送成功',
              type: 'success',
            });
            this.getData();
            this.messageDialog = false;
          } else {
            this.$message({
              message,
              type: 'error',
            });
          }
        }).catch((err) => {
          this.$message({
            message: err || 'Network error',
            type: 'error',
          });
        });
      } else {
        this.$message({
          message: '请检查输入项',
          type: 'error',
        });
      }
    },
  },
};
</script>

<style lang="less">
#pagination{
  margin-top: 30px;
}
#tag-manage-container {
  .content {
    display: flex;
    align-items: center;
    .el-input {
      margin-left: 20px;
      margin-right: 10px;
      width: 75%;
    }
  }
}
.el-icon-remove-outline {
  font-size: 20px;
}
.search-container {
  margin-bottom: 24px;
  background: #fff;
}
.list-container {
  background: #fff;
}
.new {
  text-align: right;
}
.upload-demo {
  .el-upload {
    width: 85%;
  }
  .file-container {
    width: 100%;
    display: flex;
    margin-bottom: 5px;
    .file-upload-url {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
.file-name {
  display: inline-block;
  margin-left: 10px;
}
.upload-container {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.file-notice {
  margin-top: 10px;
  color: red;
}
.el-link--primary {
  display: block;
}
.add-question {
  margin-bottom: 20px;
}
.question-info {
  display: flex;
  align-items: center;
  .must {
    width: 80px;
    text-align: center;
  }
  i {
    margin-left: 10px;
    font-size: 26px;
  }
}
.question-answers {
  .answer-info {
    margin-top: 20px;
    display: flex;
    align-items: center;
    .name {
      width: 75px;
    }
    input {
      -webkit-appearance: none;
      background-color: #FFFFFF;
      background-image: none;
      border-radius: 4px;
      border: 1px solid #DCDFE6;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      color: #606266;
      display: inline-block;
      font-size: inherit;
      height: 40px;
      line-height: 40px;
      outline: none;
      padding: 0 15px;
      -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      width: 100%;
    }
    i {
      margin: 0 20px 0 10px;
      font-size: 26px;
    }
  }
}
.newOptions {
  margin-top: 20px;;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader{
  line-height: 20px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.el-upload__tip {
  color: rgba(0,0,0,0.25);
  font-size: 14px;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 90px;
  height: 90px;
  line-height: 90px;
  text-align: center;
}
.avatar {
  width: 90px;
  height: 90px;
  display: block;
}
.message-dialog {
  .tip {
    margin-bottom: 20px;
    font-size: 16px;
    color: rgba(0,0,0,0.85);
  }
  .el-checkbox {
    width: 200px;
    margin-bottom: 8px;
    font-size: 16px;
  }
}
.form-item-inline {
  display: flex;
  > .el-form-item {
    margin-right: 20px;
  }
}
.el-date-editor--datetimerange.el-input, .el-date-editor--datetimerange.el-input__inner {
  width: 600px;
}
</style>
