<template>
  <el-dialog
    title="详情"
    :visible="visible"
    class="view-dialog"
    width="500px"
    :before-close="close">
    <el-form ref="form" label-position="top" size="mini">
      <el-form-item>
        <el-form :inline="true" size="mini" label-width="80px" class="space-between">
          <el-form-item label="姓名" required>
            <el-input width="180px" v-model="userInfo.name" disabled></el-input>
          </el-form-item>
          <el-form-item>
            <div style="width:20px"></div>
          </el-form-item>
          <el-form-item label="性别" required>
            <el-input width="180px"  :value="userInfo.sex === 1 ? '男' : '女'" disabled></el-input>
          </el-form-item>
        </el-form>
      </el-form-item>
      <el-form-item label="联系方式" required>
        <el-input
          v-model="userInfo.phone"
          disabled
        >
        </el-input>
      </el-form-item>
      <el-form-item label="身份证号" required>
        <el-input
          v-model="userInfo.idCard"
          disabled
        >
        </el-input>
      </el-form-item>
      <el-form :inline="true" label-position="top" size="mini" class="space-between">
        <el-form-item label="身高" required>
          <el-input v-model="userInfo.high" disabled></el-input>
        </el-form-item>
        <el-form-item>
            <div style="width:20px"></div>
        </el-form-item>
        <el-form-item label="体重" required>
          <el-input v-model="userInfo.weight" disabled></el-input>
        </el-form-item>
      </el-form>
      <el-form-item label="是否吸烟" required>
        <el-input width="180px"  :value="userInfo.smoke === 1 ? '是' : '否'" disabled></el-input>
      </el-form-item>
      <el-form-item label="所在地址" required>
        <el-input
          v-model="userInfo.address"
          disabled
        >
        </el-input>
      </el-form-item>
      <el-form-item label="备注信息" required>
        <el-input
          :value="userInfo.remarks"
          type="textarea"
          disabled
        >
        </el-input>
      </el-form-item>
      <el-form-item label="能否提供本人身份证" required>
        <el-input width="180px"  :value="userInfo.hasIdCard === 1 ? '能' : '否'" disabled></el-input>
      </el-form-item>
      <el-form-item label="能否提供或报告" required>
        <el-input width="180px"  :value="userInfo.hasReport === 1 ? '能' : '否'" disabled></el-input>
      </el-form-item>
      <el-form-item label="相关资料" required>
        <el-image
          v-for="src in userInfo.reportFiles"
          :key="src"
          style="width: 460px;"
          :src="src"
          :preview-src-list="userInfo.reportFiles"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close" size="mini">Close</el-button>
    </span>
  </el-dialog>
</template>

<style scoped lang="less">
.space-between {
  display: flex;
  justify-content: space-between;
  /deep/ .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
  }
}
.view-dialog /deep/ .el-input--mini {
    margin-bottom: 12px;
  }
  .view-dialog /deep/ .el-tag--light {
    margin: 0 5px 5px 0;
  }
  .el-form-item--mini.el-form-item {
    margin-bottom: 0;
  }
</style>

<script>
export default {
  name: 'UserInfoDialog',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    userInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
    };
  },
  methods: {
    close() {
      this.$emit('update:visible', false);
    },
  },
};
</script>
