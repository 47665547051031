import Vue from 'vue';
import VueRouter from 'vue-router';
// import News from '../views/News.vue';
import Swiper from '../views/Swiper.vue';
import Members from '../views/Members.vue';
import EventsManage from '../views/EventsManage.vue';
import Channel from '../views/Channel.vue';
import City from '../views/City.vue';
import Message from '../views/Message.vue';

Vue.use(VueRouter);

const routes = [{
  path: '/',
  name: 'EventsManage',
  component: EventsManage,
}, {
  path: '/members',
  name: 'Members',
  component: Members,
}, {
  path: '/swiper',
  name: 'Swiper',
  component: Swiper,
}, {
  path: '/channel',
  name: 'Channel',
  component: Channel,
}, {
  path: '/city',
  name: 'City',
  component: City,
}];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
