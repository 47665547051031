import axios from 'axios';
import Vue from 'vue';
import store from '../store';

// axios.defaults.baseURL = process.env.NODE_ENV !== 'development' ? './hobbit/' : 'http://47.90.132.136:4300/hobbit/';
axios.defaults.baseURL = './knmanager/';
axios.defaults.withCredentials = true; // 确保跨域接口能带上cookie
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
axios.defaults.transformResponse = [
  (data) => {
    try {
      const parsedData = JSON.parse(data);

      return parsedData;
    } catch (err) {
      // todo
      console.log(err);
      return null;
    }
  },
];

axios.interceptors.request.use(
  (config) => {
    Vue.prototype.$loading({
      fullscreen: true,
    });
    config.headers.token = sessionStorage.getItem('token') || '';
    // if (config.headers['content-type'] !== 'application/x-www-form-urlencoded') {
    //   // eslint-disable-next-line no-param-reassign
    //   // config.data = {
    //   //   ...config.data,
    //   //   appId: 'churchmeAdmin',
    //   //   language: 'zh-CN',
    //   //   timeZone: 'GMT+8',
    //   //   version: '1.0',
    //   //   timestamp: Date.now(),
    //   //   sign: '55bc1fd68b3b521e0926904cf73ba3f6',
    //   // };
    // }
    return config;
  },
  error => Promise.reject(error),
);

axios.interceptors.response.use(
  (response) => {
    Vue.prototype.$loading({
      fullscreen: true,
    }).close();
    const {
      data,
      status,
    } = response;
    if (status === 200 && data.code === 401) {
      store.commit('OPEN_LOGIN_DIALOG');
    }
    return response;
  },
  (error) => {
    Vue.prototype.$loading({
      fullscreen: true,
    }).close();
    return Promise.reject(error);
  },
);

export default axios;
