<template>
  <div id="tag-manage-container">
      <div class="ht-top">
        招募管理 <span>/ 渠道列表</span>
        <div class="title">
          渠道列表
        </div>
      </div>
      <div class="search-container">
        <el-form ref="form" :inline="true" label-width="140px" align="left">
          <el-form-item label="渠道名称">
            <el-input
                placeholder="请输入渠道名称"
                v-model="name"
                clearable>
              </el-input>
          </el-form-item>
          <el-form-item label="渠道手机号">
            <el-input
                placeholder="请输入渠道手机号"
                v-model="phone"
                clearable>
            </el-input>
          </el-form-item>
        </el-form>
        <div class="ht-button">
          <el-button type="primary" @click="onSearch">搜索</el-button>
          <el-button @click="onReset">重置</el-button>
        </div>
      </div>
    <div class="list-container">
      <div class="ht-middle">
        <h2 class="ht-title">渠道列表</h2>
        <el-button type="primary" @click="createDialogVisible = true">新增渠道</el-button>
      </div>
      <el-table
        :data="newsList"
        empty-text="No Data"
        stripe
        style="width: 100%">
        <el-table-column
          prop="nickName"
          label="渠道名称"
        >
        </el-table-column>
        <el-table-column
          prop="phoneNumber"
          label="手机号"
        >
        </el-table-column>
        <el-table-column
          prop="createdTime"
          label="创建时间"
        >
        </el-table-column>
        <el-table-column
          prop="recruitNum"
          label="推广人数"
        >
        </el-table-column>
        <el-table-column
          prop="partInNum"
          label="报名项目人数"
        >
        </el-table-column>
        <el-table-column
          prop="status"
          label="操作">
          <template slot-scope="scope">
            <el-button @click="deleteItem(scope.row.id, scope.row.recruitNum)" type="text">删除</el-button>
            <el-button @click="create(scope.row.id)" type="text">获取渠道二维码</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div id="pagination">
      <el-pagination
        background
        hide-on-single-page
        layout="prev, pager, next, jumper"
        @current-change="getData"
        :current-page.sync="page"
        :total.sync="total">
      </el-pagination>
    </div>
    <el-dialog
      title="新增渠道"
      :visible.sync="createDialogVisible"
      class="view-dialog"
      width="420px">
      <el-form ref="dialogForm" :model="newChannel" :rules="rules" label-position="top" size="mini" >
          <el-form-item label="渠道名称" prop="name">
            <el-input width="180px" v-model="newChannel.name"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input width="180px" v-model="newChannel.phone"></el-input>
          </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="createDialogVisible = false" size="small">取 消</el-button>
        <el-button @click="addNew" size="small" type="primary">提 交</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="渠道二维码"
      :visible.sync="qrDialogVisible"
      class="view-dialog"
      width="500px">
      <el-form>
          <el-form-item>
            <el-image
              style="width: 480px; "
              :src="url"
              :preview-src-list="[url]">
            </el-image>
          </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="qrDialogVisible = false" size="small">关闭</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="转移管理"
      :visible.sync="removeDialog"
      class="view-dialog"
      width="500px">
      <el-form>
        <el-form-item label="转移到">
          <el-select v-model="removeTargetId" placeholder="请选择" clearable>
            <el-option
              v-for="item in channelList"
              :key="item.id"
              :label="item.nickName"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="removeDialog = false" size="small">关闭</el-button>
        <el-button @click="remove" size="small">转移</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import http from '@/lib/http';

export default {
  name: 'TagManage',
  data() {
    return {
      page: 1,
      total: 0,
      name: '',
      phone: '',
      createDialogVisible: false,
      qrDialogVisible: false,
      newChannel: {
        name: '',
        phone: '',
      },
      newsList: [],
      channelList: [],
      rules: {
        name: [{ required: true, message: '渠道名称不能为空', trigger: 'change' }],
        phone: [{ required: true, message: '渠道联系方式不能为空', trigger: 'change' }],
      },
      needRemoveId: '',
      removeTargetId: '',
      removeDialog: false,
    };
  },
  computed: {
    dialogName() {
      return this.dialogStatus === 'create' ? 'Create' : 'Edit';
    },
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      http.post('login/getZmUserList', {
        pageNum: this.page,
        pageSize: 15,
        nickName: this.name,
        phoneNumber: this.phone,
      }).then((res) => {
        const { data, code, message } = res.data;
        if (code === 200) {
          this.newsList = data.dataList || [];
          this.total = data.total || 0;
        } else {
          this.$message({
            message,
            type: 'error',
          });
        }
      }).catch((err) => {
        this.$message({
          message: err || 'Network error',
          type: 'error',
        });
      });
    },
    getAllData() {
      return new Promise((resolve, reject) => {
        http.post('login/getZmUserList', {
          pageNum: 0,
          pageSize: 15,
          nickName: '',
          phoneNumber: '',
        }).then((res) => {
          const { data, code, message } = res.data;
          if (code === 200) {
            this.channelList = data.dataList || [];
            resolve();
          } else {
            this.$message({
              message,
              type: 'error',
            });
            reject();
          }
        }).catch((err) => {
          this.$message({
            message: err || 'Network error',
            type: 'error',
          });
          reject();
        });
      });
    },
    addNew() {
      this.$refs.dialogForm.validate((valid) => {
        console.log(1111, valid, this.$refs.dialogForm.validate);
        if (valid) {
          http.post('/login/createZmUser', {
            phoneNumber: this.newChannel.phone,
            nickName: this.newChannel.name,
          }).then((res) => {
            const { code, message } = res.data;
            if (code === 200) {
              this.$message({
                message: '创建成功',
                type: 'success',
              });
              this.getData();
              this.createDialogVisible = false;
            } else {
              this.$message({
                message,
                type: 'error',
              });
            }
          }).catch((err) => {
            this.$message({
              message: err || 'Network error',
              type: 'error',
            });
          });
          return null;
        }
        return false;
      });
    },
    async deleteItem(id, num) {
      console.log(num);
      if (!num) {
        this.$confirm('确认删除该渠道?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.doDelete(id);
        }).catch(() => {
        });
      } else {
        this.needRemoveId = id;
        this.removeTargetId = '';
        await this.getAllData();
        this.channelList.unshift({
          id: sessionStorage.getItem('adminId'),
          nickName: '集团',
        });
        this.removeDialog = true;
      }
    },
    doDelete(id) {
      http.post('/login/delZmUser', {
        ids: [id],
      }).then((res) => {
        const { code, message } = res.data;
        if (code === 200) {
          this.$message({
            message: '操作成功',
            type: 'success',
          });
          this.getData();
        } else {
          this.$message({
            message,
            type: 'error',
          });
        }
      }).catch((err) => {
        this.$message({
          message: err || 'Network error',
          type: 'error',
        });
      });
    },
    remove() {
      if (this.removeTargetId === '' || this.needRemoveId === this.removeTargetId) {
        this.$message({
          message: ' 请选择正确转移的目标',
          type: 'error',
        });
        return;
      }
      http.post('/login/removeZmUser', {
        fromId: this.needRemoveId,
        toId: this.removeTargetId,
      }).then((res) => {
        const { code, message } = res.data;
        if (code === 200) {
          this.doDelete(this.needRemoveId);
        } else {
          this.$message({
            message,
            type: 'error',
          });
        }
      }).catch((err) => {
        this.$message({
          message: err || 'Network error',
          type: 'error',
        });
      });
    },
    onSearch() {
      this.getData();
    },
    onReset() {
      this.page = 1;
      this.total = 0;
      this.phone = '';
      this.name = '';
      this.getData();
    },
    create(id) {
      this.url = '';
      http.get(`/wechat/getPicCode?userId=${id}`).then((res) => {
        const { data, code, message } = res.data;
        if (code === 200) {
          this.url = data;
          this.qrDialogVisible = true;
        } else {
          this.$message({
            message,
            type: 'error',
          });
        }
      }).catch((err) => {
        this.$message({
          message: err || 'Network error',
          type: 'error',
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>

#pagination{
  margin-top: 30px;
}
h2 {
  text-align: left;
}
h3 {
  text-align: left;
  margin-top: -10px;
  margin-left: 10px;
}
.new {
  text-align: right;
}
.img-container {
  display: flex;
  align-items: center;
  .el-input {
    width: 700px;
    margin-right: 20px;
  }
  button {
    height: 38px;
  }
}
</style>
