<template>
  <div id="tag-manage-container">
    <div class="ht-top">
      内容管理 <span>/ 轮播设置</span>
      <div class="title">
        首页轮播图
      </div>
      <div class="operation">
        <el-button type="primary" @click="addNew">新增一组</el-button>
        <el-button type="primary" @click="submit">保存设置</el-button>
      </div>
    </div>
    <div class="list-container">
      <div class="ht-middle">
        <h2 class="ht-title">删除和新增需要保存才能生效</h2>
        <br>
      </div>
      <el-form ref="form" :inline="true" class="swiper" v-if="list && list.length">
        <div class="swiper-item" v-for="(item, index) in list" :key="index">
          <el-tag
          >
          第 {{ index + 1 }} 组
          </el-tag>
          <el-tag
            type="danger"
            @click="delItem(index)"
          >
            删除此组
          </el-tag>
          <el-upload
            class="avatar-uploader"
            :with-credentials="true"
            action='/knmanager/banner/upFile'
            :show-file-list="false"
            :data="{fileType: 'banner'}"
            :on-success="(event, file, fileList) => listImageSuccess(index, event, file, fileList)"
            :before-upload="beforeUploadImg">
            <img v-if="item.bannerUrl" :src="item.bannerUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon">
              点此上传图片
            </i>
            <div slot="tip" class="el-upload__tip">支持的图片格式: png, jpg. 图片显示最优比例: <span style="color:red;font-weight: bold;">690:180</span>, 大小限制: ≤ 1M</div>
          </el-upload>
          <div class="item">
            <el-form-item label="地址：">
              <el-input
                type="text"
                placeholder="公众号文章地址（选填）"
                v-model="item.bannerPath"
                clearable>
              </el-input>
            </el-form-item>
          </div>
          <div class="item">
            <el-form-item label="标题：">
              <el-input
                type="text"
                placeholder="公众号文章标题（选填）"
                v-model="item.bannerTitle"
                clearable>
              </el-input>
            </el-form-item>
          </div>
          <div
            class="btn-del"
            :data-index="index"
            bindtap="delItem"
          >
          </div>
        </div>
      </el-form>
      <i v-else class="empty el-icon-setting">
        点击右上角 新增一组 进行配置
      </i>
    </div>
  </div>
</template>
<style scoped lang="less">
  #pagination{
    margin-top: 30px;
  }
  .ht-title {
    margin-bottom: 40px;
  }
  .swiper-item {
    padding-bottom: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid #e7e7e7;
  }
  .avatar-uploader {
    margin-bottom: 30px;
  }
  .avatar {
    width: 690/2px;
    height: 180/2px;
  }
  .el-icon-plus {
    width: 690/2px;
    height: 180/2px;
    font-size: 24px;
  }
  /deep/ .el-form-item__content {
    min-width: 500px;
  }
  .el-tag {
    font-weight: bold;
    margin-bottom: 20px;
  }
  .el-tag + .el-tag {
    margin-left: 40px;
    cursor: pointer;
  }
  .empty {
    padding: 20px 0;
    font-size: 24px;
    text-align: center;
    color: #666;
  }
</style>
<script>
import http from '@/lib/http';

export default {
  name: 'TagManage',
  components: {

  },
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    addNew() {
      this.list.push({
        bannerTitle: '',
        bannerUrl: '',
        bannerPath: '',
      });
    },
    delItem(index) {
      this.$confirm('确认删除该组配置?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.list.splice(index, 1);
      }).catch(() => {
      });
    },
    beforeUploadImg(file) {
      console.log(file);
      const isLt1M = (file.size / 1024 / 1024) <= 1;
      if (['image/jpg', 'image/jpeg', 'image/png'].indexOf(file.type) === -1) {
        this.$message.error('Please upload a valid file');
        return false;
      }
      if (!isLt1M) {
        this.$message.error('Uploaded file size can not exceed 1G!');
        return false;
      }
    },
    listImageSuccess(index, response, file, fileList) {
      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
      this.list[index].bannerUrl = response.data;
    },
    getData() {
      http.post('/banner/getBanner', {
        data: {
        },
      }).then((res) => {
        const { data, code, message } = res.data;
        if (code === 200) {
          this.list = data;
        } else {
          this.$message({
            message,
            type: 'error',
          });
        }
      }).catch((err) => {
        this.$message({
          message: err || 'Network error',
          type: 'error',
        });
      });
    },
    submit() {
      if (this.checkData()) {
        this.$confirm('确认保存该设置?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.postData();
        }).catch(() => {
        });
      }
    },
    checkData() {
      const invalidItemIndex = this.list.findIndex(item => !item.bannerUrl);
      if (invalidItemIndex > -1) {
        this.$message({
          message: `请检查第 ${invalidItemIndex + 1} 项`,
          type: 'error',
        });
        return false;
      }
      return true;
    },
    postData() {
      http.post('/banner/createBanner', {
        bannerList: this.list,
      }).then((res) => {
        const data = res.data || {};
        if (data.code === 200) {
          this.$message({
            message: '保存成功，去首页验证下吧',
            type: 'success',
          });
        } else {
          this.$message({
            message: data.message || '保存失败，请重试',
            type: 'error',
          });
        }
      }).catch(() => {
        this.$message({
          message: '保存失败，请重试',
          type: 'error',
        });
      });
    },
  },
};
</script>
