<template>
  <div class="dialog">
    <el-dialog :visible="loginDialogVisible" width="500px" @close="closeDialog">
      <el-form ref="form" :model="form" :rules="rules" label-width="90px">
        <el-form-item label="账户" prop="userName">
          <el-input v-model="form.userName"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model="form.password"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="footer">
        <el-button type="primary" @click="submit">登录</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import md5 from 'md5';
import { mapMutations, mapGetters } from 'vuex';
import { CLOSE_LOGIN_DIALOG, UPDATE_USERNAME } from '@/store/types';
import http from '@/lib/http';

export default {
  data() {
    return {
      form: {
        userName: '',
        password: '',
      },
      rules: {
        userName: [
          { required: true, message: 'Please enter your account', trigger: 'change' },
        ],
        password: [
          { required: true, message: 'Please enter your password', trigger: 'change' },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      loginDialogVisible: 'loginDialogVisible',
    }),
  },
  methods: {
    ...mapMutations({
      closeDialog: CLOSE_LOGIN_DIALOG,
      updateUserName: UPDATE_USERNAME,
    }),
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) { // 验证Approve
          http.post('/login/adminLogin', {
            userName: this.form.userName,
            passWord: md5(this.form.password), // 不应该驼峰，后端接口如此
          }).then((res) => {
            const { code, message, data } = res.data;
            console.log(1111, data);
            if (code === 200) {
              this.$message({
                message: '成功',
                type: 'success',
              });
              this.updateUserName(this.form.userName);
              sessionStorage.setItem('token', data.token);
              sessionStorage.setItem('adminId', data.userId);
              this.closeDialog();
              // window.location.reload();
            } else {
              this.$message({
                message,
                type: 'error',
              });
            }
          }).catch((err) => {
            this.$message({
              message: err || 'Network error',
              type: 'error',
            });
          });
        }
      });
    },
  },
};
</script>

<style lang='less'>
.dialog {
  .el-dialog__body {
    margin-right: 30px;
  }
  .footer {
    text-align: center;
    button {
      width: 90%;
    }
  }
}
</style>
