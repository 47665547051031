<template>
  <div id="tag-manage-container">
    <div class="ht-top">
      内容管理 <span>/ 模板消息</span>
      <div class="title">
        消息设置
      </div>
      <div class="operation">
      </div>
    </div>
    <div class="list-container">
      <div class="ht-middle">
        <h2 class="ht-title">消息设置</h2>
        <br>
      </div>
      <el-form ref="form" :inline="true" class="swiper">
        <el-form-item label="选择消息模板">
          <el-select v-model="projectType" placeholder="请选择" clearable>
            <el-option
              v-for="item in list"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <!-- <i v-else class="empty el-icon-setting">
        点击右上角 新增一组 进行配置
      </i> -->
    </div>
  </div>
</template>
<style scoped lang="less">
  #pagination{
    margin-top: 30px;
  }
  .ht-title {
    margin-bottom: 40px;
  }
  .swiper-item {
    padding-bottom: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid #e7e7e7;
  }
  .avatar-uploader {
    margin-bottom: 30px;
  }
  .avatar {
    width: 690/2px;
    height: 180/2px;
  }
  .el-icon-plus {
    width: 690/2px;
    height: 180/2px;
    font-size: 24px;
  }
  /deep/ .el-form-item__content {
    min-width: 500px;
  }
  .el-tag {
    font-weight: bold;
    margin-bottom: 20px;
  }
  .el-tag + .el-tag {
    margin-left: 40px;
    cursor: pointer;
  }
  .empty {
    padding: 20px 0;
    font-size: 24px;
    text-align: center;
    color: #666;
  }
</style>
<script>
import http from '@/lib/http';

export default {
  name: 'TagManage',
  components: {

  },
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      http.post('/wechat/getTemplateList', {
        data: {
        },
      }).then((res) => {
        const { data, code, message } = res.data;
        if (code === 200) {
          this.list = data;
        } else {
          this.$message({
            message,
            type: 'error',
          });
        }
      }).catch((err) => {
        this.$message({
          message: err || 'Network error',
          type: 'error',
        });
      });
    },
    submit() {
      if (this.checkData()) {
        this.$confirm('确认保存该设置?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.postData();
        }).catch(() => {
        });
      }
    },
    checkData() {
      const invalidItemIndex = this.list.findIndex(item => !item.bannerUrl);
      if (invalidItemIndex > -1) {
        this.$message({
          message: `请检查第 ${invalidItemIndex + 1} 项`,
          type: 'error',
        });
        return false;
      }
      return true;
    },
    postData() {
      http.post('/banner/createBanner', {
        bannerList: this.list,
      }).then((res) => {
        const data = res.data || {};
        if (data.code === 200) {
          this.$message({
            message: '保存成功，去首页验证下吧',
            type: 'success',
          });
        } else {
          this.$message({
            message: data.message || '保存失败，请重试',
            type: 'error',
          });
        }
      }).catch(() => {
        this.$message({
          message: '保存失败，请重试',
          type: 'error',
        });
      });
    },
  },
};
</script>
