<template>
  <div id="app">
    <Container></Container>
  </div>
</template>

<script>
import Container from '@/components/Container.vue';

export default {
  name: 'app',
  components: {
    Container,
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
html {
  background: #f5f5f5;
  font-family: Helvetica;
}
#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}
.ht-top {
  position: fixed;
  top: 48px;
  right: 0;
  left: 20px;
  height: 80px;
  padding-top: 16px;
  padding-left: 32px;
  margin: 0 0 25px 188px;
  font-size: 14px;
  color: rgba(6, 10, 20, .45);
  background: #fff;
  z-index: 7;
}
.ht-top .operation {
  position: absolute;
  right: 20px;
  top: 30px;
}
.ht-top .el-button span{
  color: #fff;
  font-weight: bold;
}
.ht-top span {
  font-family: PingFangSC-Regular, PingFang SC;
  color: rgba(6,10,20,0.65);
}
.ht-top .title {
  margin-top: 20px;
  color: #000;
  font-size: 20px;
}
.search-container {
  padding: 24px 0;
  margin-left: 208px;
  overflow: hidden;
}
.search-container .el-form-item__label {
  color: rgba(0,0,0,0.85);
}

.ht-top + .search-container, .ht-top + .list-container {
  margin-top: 120px;
}
.list-container {
  position: relative;
  padding: 22px 32px;
  margin-left: 208px;
}
.ht-middle {
  margin-bottom: 10px;
}
.ht-middle .el-button {
  position: relative;
  top: -6px;
  width: 88px;
  height: 32px;
  padding: 0;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.search-container .el-input--mini .el-input__inner {
  width: 272px;
  height: 32px;
  line-height: 32px;
}
.search-container .el-range-editor--mini.el-input__inner{
  height: 32px;
  line-height: 32px;
}
.search-container .ht-button {
  float: right;
  padding-right: 24px;
}
.search-container .ht-button .el-button {
  width: 65px;
  height: 32px;
  padding: 0;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.search-container .el-form-item--mini.el-form-item {
  margin-bottom: 24px;
}
.el-input__inner {
  border: 1px solid #DCDCDC;
}
.el-input__inner::placeholder {
  color: rgba(0,0,0,0.25);
}
.ht-title {
  font-size: 16px;
  font-weight: normal;
  color: rgba(0, 0, 0, .85);
}
.ht-middle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.list-container .el-table th.el-table__cell {
  background: rgba(220,220,220,0.15);
}
.list-container .el-table .selection  > .cell {
  padding-left: 24px;
}
.el-submenu.is-active > .el-submenu__title .el-submenu__icon-arrow {
  color: #008751;
  font-weight: bold;
}
/**
 table
*/
.el-table {
  color: rgba(0,0,0,0.65);
}
.el-table .el-table__header th.el-table__cell {
  font-weight: bold;
  color: rgba(0,0,0,0.85);
}
</style>
