<template>
  <div class="header">
    <el-row class="header-content" type="flex" justify="end" align="middle">
      <div class="user">{{userName}}</div>
      <div class="btn" @click="loginOrLogout">{{ userName ? '登 出' : '登 录'}}</div>
    </el-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { OPEN_LOGIN_DIALOG, UPDATE_USERNAME } from '@/store/types';

export default {
  name: 'Header',
  computed: {
    ...mapGetters({
      userName: 'userName',
    }),
  },
  created() {
    if (!this.userName) {
      this.login();
    }
  },
  methods: {
    ...mapMutations({
      login: OPEN_LOGIN_DIALOG,
      clearName: UPDATE_USERNAME,
    }),
    loginOrLogout() {
      if (this.userName) {
        this.clearName('');
        sessionStorage.setItem('token', '');
        window.location.reload();
      } else {
        this.login();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.header {
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 8;
  height: 48px;
  padding-right: 44px;
  font-size: 14px;
  font-family: Helvetica;
  color: #000;
  box-shadow: 0px 1px 4px 0px rgba(220, 220, 220, 0.45);
  background: #fff;
  z-index: 8;
  box-sizing: border-box;
  .header-content {
    line-height: 48px;
  }
  .user {
    height: 20px;
    padding-right: 12px;
    margin-right: 12px;
    line-height: 20px;
    border-right: 1px solid #eee;
  }
  .btn {
    width: fit-content;
    padding-right: 30px;
    background: url(../assets/icon_set.png) center right no-repeat;
    background-size: 16px auto;
    cursor: pointer;
  }
}
</style>
