<template>
  <div id="tag-manage-container">
    <div class="ht-top">
      招募管理 <span>/ 招募城市</span>
      <div class="title">
        城市设置
      </div>
      <div class="operation">
        <el-button type="primary" @click="submit">保存设置</el-button>
      </div>
    </div>
    <div class="list-container">
      <div class="ht-middle">
        <h2 class="ht-title">删除和新增需要保存才能生效</h2>
        <br>
      </div>
      <el-form ref="form" :inline="true" class="swiper" @submit.native.prevent>
        <el-tag
          :key="tag.codeDesc"
          v-for="(tag, index) in list"
          closable
          :disable-transitions="false"
          @close="handleClose(index)">
          {{tag.codeDesc}}
        </el-tag>
        <el-input
          class="input-new-tag"
          v-if="inputVisible"
          v-model.trim="inputValue"
          ref="saveTagInput"
          size="small"
          @keyup.enter.native="handleInputConfirm"
        >
        </el-input>
        <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 增加新城</el-button>
      </el-form>
    </div>
  </div>
</template>
<style scoped lang="less">
  #pagination{
    margin-top: 30px;
  }
  .ht-title {
    margin-bottom: 40px;
  }
  .swiper-item {
    padding-bottom: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid #e7e7e7;
  }
  .avatar-uploader {
    margin-bottom: 30px;
  }
  .avatar {
    width: 690/2px;
    height: 180/2px;
  }
  .el-icon-plus {
    width: 690/2px;
    height: 180/2px;
    font-size: 24px;
  }
  /deep/ .el-form-item__content {
    min-width: 500px;
  }
  .el-tag {
    margin-right: 15px;
    margin-bottom: 20px;
    font-weight: bold;
    cursor: pointer;
  }
  .empty {
    padding: 20px 0;
    font-size: 24px;
    text-align: center;
    color: #666;
  }
</style>
<script>
import http from '@/lib/http';

export default {
  name: 'TagManage',
  components: {

  },
  data() {
    return {
      list: [],
      inputVisible: false,
      inputValue: '',
    };
  },
  created() {
    this.getData();
  },
  methods: {
    handleClose(index) {
      this.$confirm('确认删除该组配置?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.list.splice(index, 1);
      }).catch(() => {
      });
    },
    getData() {
      http.get('/userCenter/getTableList?codeType=city').then((res) => {
        const { data, code, message } = res.data;
        if (code === 200) {
          this.list = data;
        } else {
          this.$message({
            message,
            type: 'error',
          });
        }
      }).catch((err) => {
        this.$message({
          message: err || 'Network error',
          type: 'error',
        });
      });
    },
    submit() {
      this.$confirm('确认保存该设置?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.postData();
      }).catch(() => {
      });
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      const { inputValue } = this;
      if (!inputValue) return;
      const index = this.list.findIndex(i => i.codeDesc === inputValue);
      if (index > -1) {
        this.$message({
          message: '该城市已在列表中',
          type: 'error',
        });
      } else {
        this.list.push({
          codeDesc: inputValue,
          codeType: 'city',
          codeValue: this.list.length,
        });
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
    postData() {
      const codeList = this.list.map((item, index) => {
        item.codeValue = index;
        return item;
      });
      http.post('/userCenter/createCodeTable', {
        codeList,
      }).then((res) => {
        const data = res.data || {};
        if (data.code === 200) {
          this.$message({
            message: '保存成功',
            type: 'success',
          });
        } else {
          this.$message({
            message: data.message || '保存失败，请重试',
            type: 'error',
          });
        }
      }).catch(() => {
        this.$message({
          message: '保存失败，请重试',
          type: 'error',
        });
      });
    },
  },
};
</script>
