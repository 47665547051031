import Vue from 'vue';
import Vuex from 'vuex';
import * as types from './types';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loginDialogVisible: false,
    userName: sessionStorage.getItem('userName') || '',
  },
  getters: {
    loginDialogVisible: state => state.loginDialogVisible,
    userName: state => state.userName,
  },
  mutations: {
    [types.OPEN_LOGIN_DIALOG]: (state) => {
      state.loginDialogVisible = true;
    },
    [types.CLOSE_LOGIN_DIALOG]: (state) => {
      state.loginDialogVisible = false;
    },
    [types.UPDATE_USERNAME]: (state, payload) => {
      state.userName = payload;
      sessionStorage.setItem('userName', payload);
    },
  },
  actions: {},
  modules: {},
});
